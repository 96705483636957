import React, {Suspense} from 'react'
import componentTypes from '@components/componentTypes'
import Loading from '@components/SuspenseLoading/Loading'
import useEffect from '@hooks/useEffect'
import useTrack from '@hooks/useTrack'

import usePage from './usePage'

export interface PageProps {
  pageId?: string
  slug?: string | string[]
  componentIdToSsrProps?: {[componentId: string]: any}
}

const SuspenseFallback = ({componentTypeId}: {componentTypeId: string}) => {
  return <Loading />
}

function PageInner(props: PageProps) {
  const page = usePage({pageId: props.pageId, slug: props.slug})

  const track = useTrack()

  useEffect(() => {
    if (page) {
      track('viewPage', {path: page.path})
    }
  }, [page?._id])

  if (!page) return null

  return (
    <div>
      {page.activeComponents.map((component, idx) => {
        const Component = componentTypes[component.componentTypeId]
        if (!Component) return `Component ${component.componentTypeId} not found`

        return (
          <div key={component._id} className={`ct-${component.componentTypeId}`}>
            <Suspense fallback={<SuspenseFallback componentTypeId={component.componentTypeId} />}>
              <Component {...component.options} id={component._id} />
            </Suspense>
          </div>
        )
      })}
    </div>
  )
}

export default function Page(props: PageProps) {
  return (
    <Suspense fallback={null}>
      <PageInner {...props} />
    </Suspense>
  )
}