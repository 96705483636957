import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useTrack() {
  const mutate = useMutate()

  const track = async (type, params?) => {
    try {
      await mutate({
        mutation: gql`
          mutation trackAction(
            $type: String
            $productId: ID
            $orderId: ID
            $path: String
            $meta: JSON
          ) {
            trackAction(
              type: $type
              productId: $productId
              orderId: $orderId
              path: $path
              meta: $meta
            )
          }
        `,
        variables: {
          ...params,
          type
        }
      })
    } catch (error) {}
  }

  return track
}
