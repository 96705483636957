import {useSuspenseQuery} from '@apollo/client'
import {GetWebsiteRoutes_CachedQuery, GetWebsiteRoutes_CachedQueryVariables} from '@data/__generated__/types.main'
import pageFragment from '@data/fragments/website/pageFragment.main'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'




export const websitePagesInfoQuery = gql`
  query getWebsiteRoutes_cached($websiteId: ID) {
    website(websiteId: $websiteId) {
      _id
      pages {
        ...pageComponents
      }
    }
  }
  ${pageFragment}
`

export default async function getWebsitePagesInfo(websiteId: string) {
  if (!websiteId) return {website: null}

  const {data} = await getClient().query<GetWebsiteRoutes_CachedQuery, GetWebsiteRoutes_CachedQueryVariables>(
    {
      query: websitePagesInfoQuery,
      variables: {
        websiteId
      }
    }
  )

  return {website: data.website}
}

export function useWebsitePagesInfo(websiteId: string) {
  const {data} = useSuspenseQuery<GetWebsiteRoutes_CachedQuery, GetWebsiteRoutes_CachedQueryVariables>(websitePagesInfoQuery, {
    variables: {websiteId},
    skip: !websiteId,
    fetchPolicy: 'cache-first'
  })

  return data.website.pages
}
