import {selectPageForSlug} from "@data/queries/page/getWebsitePage";
import {useWebsitePagesInfo} from "@data/queries/website/websitePagesInfo.main";
import useWebsiteId from "@hooks/useWebsiteId";

export default function usePage({pageId, slug}: {pageId?: string, slug?: string | string[]}) {
  const websiteId = useWebsiteId()
  const pages = useWebsitePagesInfo(websiteId)

  if (pageId) {
    const page = pages.filter(page => !page.disabled).find(page => page._id === pageId)
    return page
  }

  if (slug) {
    const page = selectPageForSlug(pages, slug)
    return page
  }

  return null
}