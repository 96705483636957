import {GetWebsiteRoutes_CachedQuery} from '@data/__generated__/types.main'
import pageFragment from '@data/fragments/website/pageFragment.main'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import getWebsitePagesInfo from '../website/websitePagesInfo.main'

import {
  getWebsitePage_cached,
  getWebsitePage_cachedVariables
} from './__generated__/getWebsitePage_cached'

export const getWebsitePageQuery = gql`
  query getWebsitePage_cached($pageId: ID, $websiteId: ID) {
    page(pageId: $pageId, websiteId: $websiteId) {
      ...pageComponents
    }
  }
  ${pageFragment}
`

export default async function getWebsitePage(websiteId: string, pageId: string) {
  const {data} = await getClient().query<getWebsitePage_cached, getWebsitePage_cachedVariables>({
    query: getWebsitePageQuery,
    variables: {
      pageId,
      websiteId
    }
  })

  return data.page
}

export async function getPageForSlug(websiteId: string, slug: string) {
  const {website} = await getWebsitePagesInfo(websiteId)
  const page = selectPageForSlug(website?.pages, slug)

  return page
}

export const selectPageForSlug = (pages: GetWebsiteRoutes_CachedQuery['website']['pages'], slug: string | string[]) => {
  const page =
    pages
      ?.filter(page => !page.disabled)
      .find(page => {
        if (page.path === slug) return true
        if (Array.isArray(slug) && slug.includes(page.path)) return true
        return false
      }) ?? null

  return page
}