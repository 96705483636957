import {getPageForSlug} from '@data/queries/page/getWebsitePage'
import getWebsiteIdFromDomain from '@data/queries/website/websiteIdFromDomain'
import getWebsitePagesInfo from '@data/queries/website/websitePagesInfo.main'
import websiteSSRTranslations from '@helpers/misc/websiteSSRTranslations'
import AbstractLayout from '@page-components/Layout/AbstractLayout'
import Website from '@page-components/Layout/Website'
import NoWebsite from '@page-components/NoWebsite'
import Page from '@page-components/Page'
import {InferGetStaticPropsType} from 'next'

export default function PagesPage(props: InferGetStaticPropsType<typeof getStaticProps>) {
  if (!props.website) {
    return <NoWebsite />
  }

  return (
    <AbstractLayout LayoutComponent={Website}>
      <Page
        pageId={props.pageId}
      />
    </AbstractLayout>
  )
}

export const getStaticProps = async context => {
  const websiteId = await getWebsiteIdFromDomain(context.params.hostname)
  const {website} = await getWebsitePagesInfo(websiteId)
  const slug = '/'

  const page = await getPageForSlug(websiteId, slug)


  const props = {
    ...(await websiteSSRTranslations(context.params.hostname)),
    websiteId,
    pageId: page?._id,
    website,
    slug,
    lastRevalidatedAt: new Date().toISOString()
  }

  if (website && !page) {
    return {
      props,
      redirect: {
        destination: '/pedir'
      },
      revalidate: 60 * 60 * 24
    }
  }

  return {
    props,
    revalidate: 60 * 60 * 24 // 1 day. It gets invalidated on demand when any server-side updates happen anyways.
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking'
  }
}
