import gql from 'graphql-tag'

export default gql`
  fragment pageComponents on Page {
    _id
    path
    disabled
    activeComponents {
      _id
      options
      componentTypeId
      schedule {
        isScheduled
      }
    }
  }
`
